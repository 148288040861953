import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  useTheme,
  SelectChangeEvent,
  Tabs,
  Tab,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getUserSessionMetrics } from "../api/helpers";
import { tokens } from "../Theme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chart-tabpanel-${index}`}
      aria-labelledby={`chart-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

interface DailyMetric {
  date: string;
  avg_sessions_per_user: number;
  avg_session_duration: number;
  avg_total_time: number;
  active_users: number;
}

const SessionCharts: React.FC<{
  timeRange: string;
  onTimeRangeChange: (range: string) => void;
}> = ({ timeRange, onTimeRangeChange }) => {
  const [dailyMetrics, setDailyMetrics] = useState<DailyMetric[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      const data = await getUserSessionMetrics(timeRange);
      if (data) {
        setDailyMetrics(data.daily_metrics);
      }
      setLoading(false);
    };

    fetchMetrics();
  }, [timeRange]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTimeRangeChange = (event: SelectChangeEvent) => {
    onTimeRangeChange(event.target.value);
  };

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    });
  };

  const formatTime = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes.toFixed(1)} min`;
    } else {
      const hours = minutes / 60;
      return `${hours.toFixed(1)} hr`;
    }
  };

  const renderTooltip = (value: number, name: string) => {
    if (name === "Avg Session Duration" || name === "Total Daily Usage") {
      return [formatTime(value), name];
    }
    return [value.toFixed(1), name];
  };

  const timeRangeOptions = [
    { value: "week", label: "Last 7 Days" },
    { value: "month", label: "Last 30 Days" },
    { value: "year", label: "Last Year" },
  ];

  if (loading) {
    return (
      <Card sx={{ height: "100%", minHeight: 400 }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h6">Session Trends</Typography>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <Select
              value={timeRange}
              onChange={handleTimeRangeChange}
              displayEmpty
            >
              {timeRangeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab label="Sessions" />
            <Tab label="Duration" />
            <Tab label="Active Users" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={dailyMetrics}
                margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  formatter={(value: any, name: any) =>
                    renderTooltip(value, name)
                  }
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="avg_sessions_per_user"
                  name="Avg Sessions Per User"
                  stroke={colors.greenAccent[500]}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={dailyMetrics}
                margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  formatter={(value: any, name: any) =>
                    renderTooltip(value, name)
                  }
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="avg_session_duration"
                  name="Avg Session Duration"
                  stroke={colors.blueAccent[500]}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="avg_total_time"
                  name="Total Daily Usage"
                  stroke={colors.redAccent[500]}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={dailyMetrics}
                margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  formatter={(value: any, name: any) =>
                    renderTooltip(value, name)
                  }
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="active_users"
                  name="Active Users"
                  stroke={colors.primary[500]}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SessionCharts;
