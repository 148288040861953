import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Typography, useTheme } from "@mui/material";
import { getFrequencyCount } from "../../api/helpers"; // Import your helper function

const chartSetting = {
  width: 1000,
  height: 500,
};

const valueFormatter = (value: number | null) => `${value}`;

export function AccessFrequencyChart() {
  const theme = useTheme();
  const [data, setData] = useState([
    { category: "Every Day", count: 0 },
    { category: "More than Week", count: 0 },
    { category: "More than Month", count: 0 },
  ]);

  useEffect(() => {
    const fetchAccessFrequencyData = async () => {
      try {
        const frequencyData = await getFrequencyCount(); // Use the helper function
        setData([
          {
            category: "Every Day",
            count: frequencyData.users_accessing_every_day,
          },
          {
            category: "More than Week",
            count: frequencyData.users_accessing_more_than_week,
          },
          {
            category: "More than Month",
            count: frequencyData.users_accessing_more_than_month,
          },
        ]);
      } catch (error) {
        console.error("Error fetching access frequency data", error);
      }
    };

    fetchAccessFrequencyData();
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        User Access Frequency
      </Typography>
      <BarChart
        dataset={data}
        xAxis={[{ scaleType: "band", dataKey: "category", label: "Category" }]}
        yAxis={[{ label: "Count" }]}
        series={[{ dataKey: "count", label: "Count", valueFormatter }]}
        layout="vertical"
        {...chartSetting}
      />
    </Box>
  );
}

export default AccessFrequencyChart;
