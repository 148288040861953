import axios from "axios";
import { ProtocolAnalyticsResponse, ProtocolCounts } from "../Types";
// const baseURL = 'http://matlaber1.media.mit.edu:8000';
const baseURL = "https://api.eon.health";
// const baseURL = 'http://127.0.0.1:8000';

export const getUsers = async (page_num: number) => {
  const api_ep = "/analytics/users/";
  try {
    // insert token in header
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        page_num: page_num,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get usage items:", error);
  }
};

export const getUserSessions = async (email: string, page_num: number) => {
  const api_ep = "/analytics/user_sessions/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        email: email,
        page_num: page_num,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get user sessions:", error);
  }
};

// Modify the existing getUsage function to accept a session_id parameter
export const getUsage = async (
  email: string,
  page_num: number,
  session_id?: string
) => {
  const api_ep = "/analytics/usage/";
  try {
    // Create params object
    const params: any = {
      email: email,
      page_num: page_num,
    };

    // Add session_id if provided
    if (session_id) {
      params.session_id = session_id;
    }

    // Make the request
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get usage items:", error);
  }
};

export const getUsageGraph = async (email: string) => {
  const api_ep = "/analytics/usagegraph/";
  try {
    // insert token in header
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        email: email,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get usage items:", error);
  }
};
export const getCurrentUserCount = async () => {
  const api_ep = "/analytics/current_user_count/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get user count:", error);
  }
};

//(Need to test this) for future Use to show the list of new Users
export const getNewUsers = async (page_num: number) => {
  const api_ep = "/analytics/new_users/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        page_num: page_num,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get new users:", error);
  }
};

export const getNewUsersCount = async () => {
  const api_ep = "/analytics/newUsersCount/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get new users count:", error);
    return null;
  }
};

export const getReturnUserCount = async () => {
  const api_ep = "/analytics/return_user_count/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get weekly home screen usage count:", error);
    throw error;
  }
};

export const getWeeklyUserStats = async () => {
  const api_ep = "/analytics/user-stats/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get weekly user stats:", error);
    throw error;
  }
};

const getScreenUsageCount = async (screen: string, timeRange: string) => {
  try {
    const response = await axios.get(
      `${baseURL}/analytics/screen_usage_count/${screen}/${timeRange}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch screen usage count:", error);
    return null;
  }
};

export const getScreenUsageCountByTimeRange = async (
  screen: string,
  timeRange: string
) => {
  return getScreenUsageCount(screen, timeRange);
};

export const getFrequencyCount = async () => {
  const api_ep = "/analytics/access-frequency/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch frequency of user");
    throw error;
  }
};

export const getUsageTree = async () => {
  const api_ep = "/analytics/usage-tree/"; // Update the endpoint based on your backend route
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    return response.data; // This should return the tree structure
  } catch (error) {
    console.error("Failed to get usage tree:", error);
    throw error; // Re-throwing the error to handle it in the component
  }
};

export const getProtocolAnalytics = async (timeRange: string) => {
  const api_ep = "/analytics/protocol-analytics/";
  try {
    const response = await axios.get<ProtocolAnalyticsResponse>(
      baseURL + api_ep,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );

    // Return the data for the selected time range directly
    return response.data[timeRange as keyof ProtocolAnalyticsResponse];
  } catch (error) {
    console.error("Failed to get protocol analytics:", error);
    return null;
  }
};

export const getUserProtocolAnalytics = async (
  timeRange: string,
  email: string
): Promise<ProtocolCounts | null> => {
  const api_ep = "/analytics/user-protocol-analytics/";
  try {
    const response = await axios.get<ProtocolCounts>(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        email: email,
        time_range: timeRange,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get user protocol analytics:", error);
    return null;
  }
};

export const getUserSessionMetrics = async (timeRange: string) => {
  const api_ep = "/analytics/user-session-metrics/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        time_range: timeRange,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get user session metrics:", error);
    return null;
  }
};

export const getPopularScreens = async (timeRange: string) => {
  const api_ep = "/analytics/popular-screens/";
  try {
    const response = await axios.get(baseURL + api_ep, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      params: {
        time_range: timeRange,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get popular screens:", error);
    return null;
  }
};
