import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { isAuthenticated, verifyToken } from "../../auth/helpers";
import { getUsage, getUserSessions, getUsers } from "../../api/helpers";
import { UsageObject, UserType, SessionObject } from "../../Types";
import { UsageData } from "../../components/usageData";
import { UserList } from "../../components/userList";
import { SessionList } from "../../components/sessionList";
import { GradientCircularProgress } from "../../components/loadingComponents";

export const Users = () => {
  const initialized = useRef(false);
  const [redirect, setRedirect] = useState(false);
  const [usageResponse, setUsageResponse] = useState<UsageObject | null>(null);
  const [sessionResponse, setSessionResponse] = useState<SessionObject | null>(
    null
  );
  const [users, setUsers] = useState<UserType[]>([]);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [sessionDataLoading, setSessionDataLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [currentUsagePage, setCurrentUsagePage] = useState(1);
  const [currentSessionPage, setCurrentSessionPage] = useState(1);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const authStatus = isAuthenticated();
      if (!authStatus) {
        setRedirect(true);
        return;
      }

      verifyToken()
        .then((status) => {
          if (status) {
            loadUsers(1);
          } else {
            setRedirect(true);
          }
        })
        .catch(() => setRedirect(true));
    }
  }, []);

  const loadUsers = async (pageNum: number) => {
    try {
      const response = await getUsers(pageNum);
      if (response) {
        if (pageNum === 1) {
          setUsers(response.users);
        } else {
          setUsers((prev) => [...prev, ...response.users]);
        }
        setTotalPages(response.total_pages);
      }
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  const loadSessionData = async (email: string, page: number = 1) => {
    try {
      setSessionDataLoading(true);
      const response = await getUserSessions(email, page);
      if (response) {
        setSessionResponse((prev) => ({
          ...prev,
          [email]: response,
        }));
      }
      setSessionDataLoading(false);
    } catch (error) {
      console.error("Error loading session data:", error);
      setSessionDataLoading(false);
    }
  };

  const loadUsageData = async (
    email: string,
    sessionId: string,
    page: number = 1
  ) => {
    try {
      setUserDataLoading(true);
      const response = await getUsage(email, page, sessionId);
      if (response) {
        setUsageResponse((prev) => ({
          ...prev,
          [`${email}-${sessionId}`]: response,
        }));
      }
      setUserDataLoading(false);
    } catch (error) {
      console.error("Error loading usage data:", error);
      setUserDataLoading(false);
    }
  };

  const handleUserSelect = (email: string) => {
    setSelectedUser(email);
    setSelectedSession("");
    setCurrentSessionPage(1);
    setCurrentUsagePage(1);

    if (!sessionResponse?.[email]) {
      loadSessionData(email, 1);
    }
  };

  const handleSessionSelect = (sessionId: string) => {
    setSelectedSession(sessionId);
    setCurrentUsagePage(1);

    if (!usageResponse?.[`${selectedUser}-${sessionId}`]) {
      loadUsageData(selectedUser, sessionId, 1);
    }
  };

  const handleLoadMore = () => {
    if (currentPageNum < totalPages) {
      setCurrentPageNum((prev) => prev + 1);
      loadUsers(currentPageNum + 1);
    }
  };

  const handleSessionPageChange = (page: number) => {
    setCurrentSessionPage(page);
    loadSessionData(selectedUser, page);
  };

  const handleUsagePageChange = (page: number) => {
    setCurrentUsagePage(page);
    loadUsageData(selectedUser, selectedSession, page);
  };

  if (redirect) return <Navigate to="/login" />;
  if (users.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <GradientCircularProgress />
        <Typography variant="h5" ml={3}>
          Loading users...
        </Typography>
      </Box>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "100vh",
        flex: 1,
      }}
    >
      <Box sx={{ display: "flex", width: "80%", flexDirection: "row" }}>
        <Box sx={{ display: "flex", flex: 2, flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "center", pl: 2, pr: 2 }}>
            <UserList
              users={users}
              selectedUser={selectedUser}
              onUserSelect={handleUserSelect}
              handleLoadMore={handleLoadMore}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flex: 8, ml: 2, flexDirection: "column" }}>
          {selectedUser && sessionDataLoading && <GradientCircularProgress />}
          {selectedUser &&
            !sessionDataLoading &&
            sessionResponse &&
            sessionResponse[selectedUser] && (
              <Box sx={{ mb: 4 }}>
                <SessionList
                  sessions={sessionResponse[selectedUser].sessions}
                  total_pages={sessionResponse[selectedUser].total_pages}
                  isLoading={sessionDataLoading}
                  onPageChange={handleSessionPageChange}
                  currentPage={currentSessionPage}
                  onSessionSelect={handleSessionSelect}
                  selectedSession={selectedSession}
                />
              </Box>
            )}
          {selectedUser && selectedSession && userDataLoading && (
            <GradientCircularProgress />
          )}
          {selectedUser &&
            selectedSession &&
            !userDataLoading &&
            usageResponse &&
            usageResponse[`${selectedUser}-${selectedSession}`] && (
              <UsageData
                usages={
                  usageResponse[`${selectedUser}-${selectedSession}`].usages
                }
                total_pages={
                  usageResponse[`${selectedUser}-${selectedSession}`]
                    .total_pages
                }
                isLoading={userDataLoading}
                onPageChange={handleUsagePageChange}
                currentPage={currentUsagePage}
              />
            )}
          {!selectedUser && (
            <Typography variant="h5" gutterBottom>
              Select a user to view their sessions
            </Typography>
          )}
          {selectedUser && !selectedSession && !sessionDataLoading && (
            <Typography variant="h5" gutterBottom>
              Select a session to view usage details
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Users;
