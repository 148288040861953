import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
} from "@mui/material";
import { getPopularScreens } from "../api/helpers";
import { tokens } from "../Theme";

interface ScreenData {
  screen_name: string;
  visits: number;
  unique_users: number;
}

const PopularScreens: React.FC<{ timeRange: string }> = ({ timeRange }) => {
  const [screens, setScreens] = useState<ScreenData[]>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getPopularScreens(timeRange);
      if (data) {
        setScreens(data);
      }
      setLoading(false);
    };

    fetchData();
  }, [timeRange]);

  if (loading) {
    return (
      <Card sx={{ minHeight: 400 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Most Visited Screens
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        </CardContent>
      </Card>
    );
  }

  // Find the maximum number of visits to calculate percentages
  const maxVisits = screens.length > 0 ? screens[0].visits : 0;

  return (
    <Card sx={{ minHeight: 400 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Most Visited Screens
        </Typography>
        <List sx={{ width: "100%" }}>
          {screens.map((screen, index) => (
            <React.Fragment key={screen.screen_name}>
              <ListItem
                alignItems="flex-start"
                sx={{ flexDirection: "column", py: 1 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <ListItemText
                    primary={screen.screen_name}
                    secondary={`${screen.unique_users} unique users`}
                    primaryTypographyProps={{
                      fontWeight: index < 3 ? "bold" : "normal",
                    }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {screen.visits.toLocaleString()} visits
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={(screen.visits / maxVisits) * 100}
                  sx={{
                    width: "100%",
                    height: 8,
                    borderRadius: 4,
                    bgcolor: colors.grey[200],
                    "& .MuiLinearProgress-bar": {
                      bgcolor:
                        index === 0
                          ? colors.greenAccent[500]
                          : index === 1
                          ? colors.blueAccent[500]
                          : index === 2
                          ? colors.primary[500]
                          : colors.grey[500],
                    },
                  }}
                />
              </ListItem>
              {index < screens.length - 1 && (
                <Divider variant="middle" component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PopularScreens;
