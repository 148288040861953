import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { getUserSessionMetrics } from "../api/helpers";
import { tokens } from "../Theme";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import PeopleIcon from "@mui/icons-material/People";

interface MetricProps {
  title: string;
  value: string;
  subtitle: string;
  icon: React.ReactNode;
  color: string;
}

const Metric: React.FC<MetricProps> = ({
  title,
  value,
  subtitle,
  icon,
  color,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card
      sx={{
        height: "100%",
        transition: "0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {value}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {subtitle}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: "50%",
              p: 1,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
            }}
          >
            {icon}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const SessionMetrics: React.FC<{ timeRange: string }> = ({ timeRange }) => {
  const [metrics, setMetrics] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      const data = await getUserSessionMetrics(timeRange);
      if (data) {
        setMetrics(data.overall_metrics);
      }
      setLoading(false);
    };

    fetchMetrics();
  }, [timeRange]);

  const formatTime = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes.toFixed(1)} mins`;
    } else {
      const hours = minutes / 60;
      return `${hours.toFixed(1)} hrs`;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography color="error">Failed to load metrics</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Metric
          title="Sessions Per User"
          value={metrics.avg_sessions_per_user.toFixed(1)}
          subtitle="Average per user"
          icon={<TrendingUpIcon />}
          color={colors.greenAccent[600]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Metric
          title="Session Duration"
          value={formatTime(metrics.avg_session_duration)}
          subtitle="Average per session"
          icon={<AccessTimeIcon />}
          color={colors.blueAccent[600]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Metric
          title="Daily App Usage"
          value={formatTime(metrics.avg_total_time)}
          subtitle="Per user daily"
          icon={<CalendarViewDayIcon />}
          color={colors.redAccent[600]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Metric
          title="Active Users"
          value={Math.round(metrics.avg_active_users).toString()}
          subtitle="Daily average"
          icon={<PeopleIcon />}
          color={colors.primary[600]}
        />
      </Grid>
    </Grid>
  );
};

export default SessionMetrics;
