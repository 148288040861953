import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { TotalCustomers } from "./TotalCustomers";
import { getCurrentUserCount, getNewUsersCount } from "../api/helpers";
import PieActiveArc from "./dashboardPieChart";
import ReturningUsers from "./returningUsers";
import WeeklyUsersChart from "./weeklyUserStats";
import SessionMetrics from "./sessionMetrics";
import SessionCharts from "./sessionCharts";
import PopularScreens from "./popularScreens";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Dashboard: React.FC = () => {
  const [userCount, setUserCount] = useState<number>(0);
  const [newUserCount, setNewUserCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [sessionTimeRange, setSessionTimeRange] = useState("month");

  useEffect(() => {
    const fetchUserCounts = async () => {
      try {
        const currentUsers = await getCurrentUserCount();
        const newUsers = await getNewUsersCount();

        if (currentUsers && newUsers) {
          setUserCount(currentUsers.count);
          setNewUserCount(newUsers.newUsersCount);
        } else {
          setError("Failed to fetch user counts");
        }
      } catch (error) {
        setError("Error fetching user counts");
      }
    };

    fetchUserCounts();
  }, []);

  const previousUserCount = userCount - newUserCount;
  const calculateTrendAndDiff = () => {
    const diff =
      previousUserCount > 0
        ? ((newUserCount / previousUserCount) * 100).toFixed(2)
        : "0";
    const trend: "up" | "down" = newUserCount > 0 ? "up" : "down";
    return { diff, trend };
  };

  const { diff, trend } = calculateTrendAndDiff();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSessionTimeRangeChange = (range: string) => {
    setSessionTimeRange(range);
  };

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Dashboard
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      <Paper sx={{ mb: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="dashboard tabs"
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="User Overview" />
            <Tab label="Session Analytics" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          {/* User Overview Tab */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <TotalCustomers
                value={userCount.toString()}
                trend={trend}
                diff={parseFloat(diff)}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <PieActiveArc
                newUserCount={newUserCount}
                previousUserCount={previousUserCount}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ReturningUsers />
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Grid>
            <WeeklyUsersChart />
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {/* Session Analytics Tab */}
          <Box mb={4}>
            <SessionMetrics timeRange={sessionTimeRange} />
          </Box>

          <Divider sx={{ my: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <SessionCharts
                timeRange={sessionTimeRange}
                onTimeRangeChange={handleSessionTimeRangeChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PopularScreens timeRange={sessionTimeRange} />
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default Dashboard;
