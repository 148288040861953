import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  CardActions,
  Button,
  Pagination,
  CircularProgress,
  Stack,
  useTheme,
} from "@mui/material";
import { UsageItem } from "../Types";
import { tokens } from "../Theme";

interface UsageDataProps {
  usages: UsageItem[];
  total_pages: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  currentPage: number;
}

export const UsageData: React.FC<UsageDataProps> = ({
  usages,
  total_pages,
  isLoading = false,
  onPageChange,
  currentPage,
}) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onPageChange(page);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Usage Data
      </Typography>
      <Divider />

      {usages.length === 0 ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <Typography variant="body1" color="text.secondary">
            No usage data available
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
            {usages.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.item_id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[600],
                      boxShadow: "0px 4px 20px rgba(46, 194, 36, 0.1)",
                      transform: "translateY(-5px)",
                      transition: "all 0.3s ease-in-out",
                    },
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {new Date(item.usage_timestamp).toLocaleString()}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {item.screen_name}
                    </Typography>
                    {item.screen_name === "DailyInfoScreen" &&
                      "protocol_name" in item && (
                        <Typography variant="h5" sx={{}}>
                          {`Protocol: ${item.protocol_name}`}
                        </Typography>
                      )}
                    <Typography variant="body2">{item.item_pressed}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Session: {item.session_id}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="warning">
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
            <Pagination
              count={total_pages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default UsageData;
