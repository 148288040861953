import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  Pagination,
  CircularProgress,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../Theme";
import { Session } from "../Types";

interface SessionListProps {
  sessions: Session[];
  total_pages: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  currentPage: number;
  onSessionSelect: (sessionId: string) => void;
  selectedSession: string | null;
}

export const SessionList: React.FC<SessionListProps> = ({
  sessions,
  total_pages,
  isLoading = false,
  onPageChange,
  currentPage,
  onSessionSelect,
  selectedSession,
}) => {
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onPageChange(page);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        User Sessions
      </Typography>
      <Divider />

      {sessions.length === 0 ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <Typography variant="body1" color="text.secondary">
            No sessions available
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
            {sessions.map((session) => {
              const deviceId = session.device_id;
              const appVersion = session.app_version;
              const platform = session.platform;
              const start = new Date(session.start_time);
              const end = new Date(session.estimated_end_time);
              const durationSec = (end.getTime() - start.getTime()) / 1000;
              const durationFormatted =
                durationSec < 60
                  ? `${Math.round(durationSec)} seconds`
                  : `${Math.round(durationSec / 60)} minutes`;

              return (
                <Grid item xs={12} sm={6} md={4} key={session.session_id}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor:
                        selectedSession === session.session_id
                          ? colors.greenAccent[700]
                          : "inherit",
                      "&:hover": {
                        backgroundColor: colors.greenAccent[600],
                        boxShadow: "0px 4px 20px rgba(46, 194, 36, 0.1)",
                        transform: "translateY(-5px)",
                        transition: "all 0.3s ease-in-out",
                      },
                      cursor: "pointer",
                    }}
                    variant="outlined"
                    onClick={() => onSessionSelect(session.session_id)}
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Session - {durationFormatted}
                      </Typography>
                      <Typography variant="body2">
                        Start: {start.toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        End: {end.toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Items: {session.item_count}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {platform} • v{appVersion}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            display: "block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Device ID: {deviceId}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
            <Pagination
              count={total_pages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default SessionList;
